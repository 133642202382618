<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center"
  >
    <div
      class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4"
      style="transform: translateY(-50%);"
    >
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">
            Order Completed!
          </h2>
          <p class="text-center">
            Thank you, your order has been placed.
          </p>
          <br />
          <p class="text-center">Order # - {{ orderNumber }}</p>
          <div class="flex flex-wrap justify-center">
            <vs-button
              icon-pack="feather"
              icon="icon-eye"
              @click="viewOrderRoute"
              >View Order</vs-button
            >
          </div>
          <p class="text-center">
            This page will close in {{ seconds }} seconds.....
          </p>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClinicOrderComplete",
  data() {
    return {
      orderId: "",
      orderNumber: "",
      seconds: 10,
      interval: null
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.orderNumber = this.$route.query.orderNumber;
    this.counter();
  },
  methods: {
    counter() {
      const self = this;
      this.interval = setInterval(function() {
        if (self.seconds == 0) {
          clearInterval(self.interval);
          let name = "OrderDetail";
          if (self.$store.state.AppActiveUser.userType === "doctorClinic") {
            name = "DoctorClinicOrderDetail";
          }
          self.$router.push({ name, params: { orderId: self.orderId } });
        } else console.log("Currently at " + self.seconds--);
      }, 1000);
    },
    viewOrderRoute() {
      clearInterval(this.interval);
      let name = "OrderDetail";
      if (this.$store.state.AppActiveUser.userType === "doctorClinic") {
        name = "DoctorClinicOrderDetail";
      }
      this.$router
        .push({ name, params: { orderId: this.orderId } })
        .catch(() => {});
    }
  },
  destroyed() { 
    clearInterval(this.interval)
  }
};
</script>

<style scoped></style>
